// game page 

.games-wrapper {

    margin: 40px 45px;
    text-align: left;

    h1 {
        color: #000;
        font-size: 18px;
        text-align: left;
        margin: 40px 0 16px;
    }

    p.no-games {
        margin-left: 25px;
    }
}

.game-content-container {
    margin: 100px 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: #000;

    .mid-column {
        width: 100%;
    }
}


/* form */
.add-game-form,
.add-collection-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2c3440;
    padding: 24px 50px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    z-index: 25;
    color: #bcd;
    margin-top: -10px; // fix: vertically not really centered?

    label {
        margin: 20px 0 5px;
    }

    .form-buttons {
        margin-top: 16px;

        button {
            margin: 0 10px;
        }
    }

    .add-album-info {
        p {
            margin: 0 0 10px;
            padding: 0;
        }

        .example-group {
            margin-bottom: 12px;

            .example {
                line-height: 1;
                padding: 0;
                margin: 0 0 5px;

                span {
                    display: inline-block;
                    width: 40px;
                }
            }

            .example--bad {
                color: rgb(214, 37, 37);
            }

            .example--good {
                color: rgb(139, 216, 24);
            }
        }
    }
}

/* form */
.popup-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2c3440;
    padding: 24px 50px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    z-index: 25;
    color: #bcd;

    label {
        margin: 20px 0 5px;
    }

    .form-buttons {
        margin-top: 16px;

        button {
            margin: 0 10px;
        }
    }
}