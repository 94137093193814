.left-bar {
    width: $leftbar-width;
    height: calc(100vh - 72px);
    box-sizing: border-box;
    background-color: #F8F8F9;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 1px solid #EAEAEA;
    text-align: left;
    padding: 9px 0px;

    .site-logo {
        padding: 13px 24px 0;
        box-sizing: content-box;
        // width: 124px;
        width: 120px;
        filter: grayscale(100%);
        transition: .25s ease-in-out;
    }

    .site-logo:hover {
        filter: grayscale(0%);
    }

    .leftbar-titles {
        margin-top: 52px;
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        row-gap: 25px;

        a {
            text-decoration: none;
            color: unset;
            border-left: 4px solid transparent;
        }

        .leftbar-title-container {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            cursor: pointer;
            border-left: 4px solid transparent;
            transition: .08s ease-in-out;

            .leftbar-title-icon {
                margin-left: 20px;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            .leftbar-title-text {
                font-weight: bold;
                font-size: 17px;
                // font-family: 'Roboto', sans-serif;
                font-family: 'Cabin Condensed', sans-serif;
                font-size: 17px;
                margin-top: 1px;
                padding-top: 1px;
            }
        }

        .leftbar-title-container:hover {
            color: $primary-color;
        }

        .leftbar-title-container:hover .leftbar-title-icon svg {
            fill: $primary-color;
            transition: .08s ease-in-out;
        }

        /* active */
        .active {
            color: $primary-color;
            border-left: 4px solid $primary-color;
        }

        .active svg {
            fill: $primary-color;
        }

    }
}

@media only screen and (max-width: 1023px) {
    .left-bar {
        width: 100%;
        // max-width: 100vw;
        height: 70px;
        left: 0;
        bottom: 0;
        top: unset;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // z-index: 26;
        z-index: 37;
        /* .playing-container--page block buttons fix */

        .site-logo {
            display: none;
        }

        .leftbar-titles {
            margin-top: 0;
            height: unset;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            a {
                // border-left: 4px solid transparent;
                border-left: none;
            }

            .leftbar-title-container {
                flex-direction: column;
                row-gap: 2px;
                border-left: none;

                .leftbar-title-icon {
                    margin-left: 0;
                    text-align: center;

                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }

                .leftbar-title-text {
                    font-size: 12px;
                    margin-top: 0;
                }
            }

            .leftbar-title-container:hover {
                color: $primary-color;
                /* use this to change the album svg color */
            }

            .leftbar-title-container:hover .leftbar-title-icon svg {
                fill: $primary-color;
            }

            /* active */
            .active {
                border-left: none;
            }

            .active svg {
                fill: $primary-color;
            }

        }

    }
}