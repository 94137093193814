.holder {

    // height: 450px;
    z-index: 1;
    position: relative;
    width: 100%;
    min-height: 350px;

    .component-banner-container {
        height: 450px;
        width: 100%;
        display: flex;
        flex-direction: row;

        .component-banner-infos-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 70%;
            height: 100%;
            text-align: left;
            padding: 0px 50px;
            display: flex;
            align-items: center;

            .component-banner-infos-content {

                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .banner-tags-container {

                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    margin-bottom: 14px;

                    .tag {
                        // background-color: greenyellow;
                        background-color: #ddd;
                    }
                }

                .collection-banner-tags-container {
                    .tag {
                        background-color: rgb(0, 128, 255);
                        color: #fff;
                    }
                }

                .game-cover {
                    /* only in game pages */
                    width: 220px;
                    // height: 270px;
                    aspect-ratio: 0.742;
                    border-radius: 8px;
                    background-size: cover;
                    background-position: center;
                    opacity: 1;
                }

                h1 {
                    font-size: 48px;
                    line-height: 1.2;
                    margin: 0;
                    cursor: default;
                    margin-top: -10px;
                    font-family: 'Cabin Condensed', sans-serif;
                    // line-height: 1.5;
                    color: #eee;
                }

                .component-description {
                    width: 60%;
                    max-height: 140px;
                    font-family: Roboto;
                    overflow-y: scroll;
                    cursor: default;
                    // color: #3d4d5c;
                    color: #eee; 
                    font-size: 13px;
                    font-family: 'Noto Sans Bengali', sans-serif;

                    .toggle-description {
                        cursor: pointer;
                    }

                    .line {
                        font-size: 13px;
                    }
                }

                .component-description::-webkit-scrollbar {
                    width: 0;
                }

                .component-description::-webkit-scrollbar-thumb {
                    background-color: transparent;
                }

                /* For Firefox */
                .component-description {
                    scrollbar-width: thin;
                    scrollbar-color: transparent transparent;
                }

                .component-banner-buttons {
                    max-width: 300px;

                    svg {
                        fill: $primary-color;
                        cursor: pointer;
                        width: 25px;
                        opacity: .4;
                    }
                }

            }

        }

        .component-banner-left {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            padding-left: 75px;
            align-items: center;

        }

        .component-banner-right {
            width: 70%;
            height: 100%;
            position: relative;

            .component-banner {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-position-x: 100%;
                transition: 1s ease-in-out;
            }

            .component-banner-right--gradient-bg {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

        }

    }


    .game-banner-container {
        height: 400px;
        transition: 1s ease-in-out;
    }

}



@media only screen and (max-width: 1023px) {

    .holder {

        // height: 450px;
        z-index: 1;
        position: relative;
        width: 100%;
        min-height: 270px;
        margin-top: 0px;
        padding: 0;

        .component-banner-container {
            height: 270px;
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 0;

            .component-banner-infos-container {
                position: absolute;
                width: 70%;
                height: 100%;
                text-align: left;
                padding: 0px 20px;
                display: flex;
                align-items: center;

                .component-banner-infos-content {

                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    .banner-tags-container {

                        display: flex;
                        flex-direction: row;
                        column-gap: 10px;
                        margin-bottom: 14px;

                        .tag {
                            // background-color: greenyellow;
                            background-color: #ddd;
                        }
                    }

                    .collection-banner-tags-container {
                        .tag {
                            background-color: rgb(0, 128, 255);
                            color: #fff;
                        }
                    }

                    .game-cover {
                        /* only in game pages */
                        width: 220px;
                        // height: 270px;
                        aspect-ratio: 0.742;
                        border-radius: 8px;
                        background-size: cover;
                        background-position: center;
                        opacity: 1;
                    }

                    h1 {
                        font-size: 28px;
                        line-height: 1.2;
                        margin: 0;
                        cursor: default;
                        margin-top: -10px;
                        font-family: 'Cabin Condensed', sans-serif;
                        // line-height: 1.5;
                    }

                    .component-description {
                        width: 60%;
                        max-height: 140px;
                        font-family: Roboto;
                        overflow-y: scroll;
                        cursor: default;
                        color: #3d4d5c;
                        font-size: 13px;
                        font-family: 'Noto Sans Bengali', sans-serif;
                        display: none;

                        .toggle-description {
                            cursor: pointer;
                        }

                        .line {
                            font-size: 13px;
                        }
                    }

                    .component-description::-webkit-scrollbar {
                        width: 0;
                    }

                    .component-description::-webkit-scrollbar-thumb {
                        background-color: transparent;
                    }

                    /* For Firefox */
                    .component-description {
                        scrollbar-width: thin;
                        scrollbar-color: transparent transparent;
                    }

                    .component-banner-buttons {
                        max-width: 300px;

                        svg {
                            cursor: pointer;
                            width: 28px;
                            opacity: .15;
                        }
                    }

                }

            }

            .component-banner-left {
                width: 30%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                padding-left: 75px;
                align-items: center;

            }

            .component-banner-right {
                width: 70%;
                height: 100%;
                position: relative;

                .component-banner {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-position-x: 100%;
                    transition: 1s ease-in-out;
                }

                .component-banner-right--gradient-bg {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

            }

        }

        .game-banner-container {
            height: 270px;
            transition: 1s ease-in-out;
        }

    }


}