.top-bar-container {
    height: $topbar-height;
    // width: calc(100% - 220px);
    width: calc(100% - $leftbar-width);
    margin-left: $leftbar-width;
    text-align: left;
    padding-right: 25px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 25;
    background-color: #fff;
    transition: $layout-change-transition;

    .searchbar-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding-left: 23px;
        width: 60%;

        svg {
            width: 16px;
        }

        input {
            height: 100%;
            width: 100%;
            outline: none;
            border: none;
            padding-left: 18px;
        }
    }

    .topbar-right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 25px;
    }

    .logged-user-infos {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        font-size: 13px;
        text-align: center;
        color: rgb(154, 154, 154);
        font-family: 'Noto Sans Bengali', sans-serif;

        .logged-infos-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            column-gap: 10px;

            img {
                height: 36px;
                /* 30 */
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 50%;
                cursor: pointer;
            }

            .logout-name-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                row-gap: 0;

                .logout-btn {
                    border: none;
                    background-color: transparent;
                    outline: none;
                    font-size: 12px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px 14px 4px;
                    margin: 0;
                    color: black;
                    box-shadow: none;
                }

                div.email {
                    cursor: default;
                    user-select: none;
                }
            }
        }

    }

    .login-infos-container {
        button {
            font-size: 14px;
            background-color: transparent;
            border: 1px solid $primary-color;
            outline: none;
            border-radius: 20px;
            color: $primary-color;
            padding: 8px 32px;
            font-weight: bold;
            transition: .2s ease-in-out;
        }

        button:hover {
            background-color: #ffcfd2;
        }
    }


}

.top-bar-container-player-enabled {
    width: calc(100% - $leftbar-width - $rightbar-width);
}

@media only screen and (max-width: 1023px) {
    .top-bar-container {
        z-index: 37;
    }
    .coffee-link {
        display: none;
    }
}