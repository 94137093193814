.albums-wrapper {
    margin: 40px 45px;
    box-sizing: border-box;
    text-align: left;

    .common-form-btns {
        justify-content: flex-start;
        position: relative;
    }

    form {
        .common-form-btns {
            justify-content: center;
            position: absolute;
        }
    }

    .no-albums {
        margin-left: 25px;
    }

    h1 {
        color: #000;
        font-size: 18px;
        text-align: left;
        margin: 40px 0 16px;
    }

    .albums-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 22px;
        /*28*/
        row-gap: 25px;
        padding: 0;

        .album-container {
            overflow: visible;
            color: #000;
            font-size: 20px;
            cursor: pointer;
            position: relative;
            transition: .15s ease-in-out;
            text-decoration: none;
            width: 200px;
            // flex: 1 0 24%; 

            .album-cover-container {
                aspect-ratio: 1;
                border-radius: 6px;
                margin: 0;
                cursor: pointer;
                background-size: cover;
                background-position: center;
                position: relative;
                width: 100%;

                .album-btns-container {

                    .love-btn-container,
                    .options-btn-container {
                        opacity: 0;
                        transition: .1s ease-in-out;
                    }
                }
            }

            .album-cover-container:hover .love-btn-container,
            .album-cover-container:hover .options-btn-container {
                opacity: 1;
            }

            .album-cover-container:after {
                background-color: #fff;
                bottom: 0;
                content: "";
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition-duration: .15s;
                transition-property: opacity, background-color;
            }

            .album-cover-container:hover::after {
                opacity: .2;
            }

        }

        .album-cover-container:hover img.play-icon {
            opacity: 1;
        }

        .album-infos-container {
            margin: 9px 0 0;
            text-align: left;

            .album-title {
                font-size: 13px;
                padding: 0px 2px 0;
                margin-bottom: 3px;
                font-family: 'Noto Sans Bengali', sans-serif;
                line-height: 1.2;
                cursor: default;
            }

            .album-highlighted-info {
                // padding: 0 2px;
                line-height: 1.3; // 1.3
                padding: 0 2px;
                font-size: 11px;
                font-family: 'Noto Sans Bengali', sans-serif;
                color: rgb(180, 180, 180);
                cursor: default;
                a {
                    cursor: pointer;
                    text-decoration: none;
                }
                span {
                    // color: $primary-color;
                    color: #000;
                }
            }

            .album-year {}

            .album-likes-container {}
        }


        .album-container--empty {
            .album-cover-container {
                align-items: center;
                justify-content: center;
                display: flex;
                padding-top: 7px;
                font-size: 90px;
                color: #ccc;
                transition: .2s ease-in-out;
            }
        }

        .album-container--empty:hover .album-cover-container {
            // color: #eee;
            font-size: 100px;
        }
    }
}

.albums-wrapper-simplified {

    display: block;
    margin: 0;
    padding: 0 36px;
    margin-top: 16px;

    .album-container-simplified {
        width: 100%;
        text-align: left;
        display: block;
        padding: 8px;
        border: 1px solid #ccc;
        font-size: 14px;
        margin-bottom: 12px;
        transition: .5s ease-in-out;
        cursor: grab;

        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;

    }
}


@media only screen and (max-width: 1023px) {

    .albums-wrapper {
        margin-left: 0;
        margin-right: 0;
        padding: 0 16px;

        h1 {
            text-align: left;
            margin: 40px 0 16px;
        }

        .albums-container {
            column-gap: 16px;
            row-gap: 20px;

            .album-container {
                overflow: visible;
                font-size: 20px;
                width: 46.5%;
                max-width: 200px;

                .album-cover-container {
                    width: 100%;

                    .album-btns-container {

                        .love-btn-container,
                        .options-btn-container {
                            opacity: 1;
                        }
                    }
                }


                .album-cover-container:after {}

                .album-cover-container:hover::after {
                    opacity: .2;
                }

            }

            .album-cover-container:hover img.play-icon {
                opacity: 1;
            }


            .album-infos-container {
                margin: 6px 0 0;

                .album-title {}

                .album-year {}
            }


            .album-container--empty {
                display: none;

                .album-cover-container {}
            }

            .album-container--empty:hover .album-cover-container {}
        }
    }

}