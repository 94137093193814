.settings-content-container {
    margin: 40px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    input {
        padding: 6px 10px;
        width: 340px;
    }

    div.button-container {
        text-align: left;
        margin-top: 16px;

        button {
            font-size: 15px;
            padding: 10px 27px;
            border-radius: 20px;
            font-weight: bold;
            color: #eee;
        }

    }

    .username-change {
        text-align: left;

        span {
            display: block;
            font-size: 13px;
            margin: 3px 1px;
            color: darkred;
        }
    }

}