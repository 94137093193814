.favorites-content-container {

    .favorites-banner-container {
        padding: 0 72px;
        height: 200px;
        margin: 24px auto 32px;
        display: flex;
        align-items: center;
        column-gap: 32px;

        .pp-container {
            height: 200px;
            aspect-ratio: 1;
            min-width: 200px;
            border-radius: 50%;
            overflow: hidden;
            background-color: rgb(223, 223, 223);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .profile-infos-container {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            padding-bottom: 8px;

            .profile-name {
                text-align: left;
                font-size: 30px;
                font-weight: bold;
                font-family: Roboto;
                line-height: 1;
            }

            .member-infos {
                text-align: left;
                color: grey;
                font-size: 13px;
            }

            .shuffle-favorites-container {
                text-align: left;

                button {
                    background-color: $primary-color;
                    padding: 10px 27px;
                    border-radius: 20px;
                    color: #eee;
                    border: none;
                    outline: none;
                    font-weight: bold;
                    font-size: 15px;
                }
            }
        }
    }

    .content-navbar-container {
        padding: 0 72px 8px;
        border-bottom: 1px solid #EAEAEA;
        width: 100%;
        display: flex;
        font-size: 16px;
        color: #333;
        font-family: Roboto;
        justify-content: flex-start;
        flex-direction: row;
        column-gap: 48px;

        a {
            text-decoration: none;
            color: unset;
            position: relative;
        }

        a:hover::after {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
            background-color: black;
        }

        .active:after {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color !important;
        }
    }

    .profile-stats-container {

        text-align: left;
        margin: 50px 72px;

        /*
        div {
            font-size: 15px;
            margin: 12px 0;
            color: #191919;
            font-family: Roboto;
        }
        */
    }
}

@media only screen and (max-width: 1023px) {

    .favorites-content-container {

        .favorites-banner-container {
   
            height: unset;
            flex-direction: column;

            .pp-container {

                img {

                }
            }

            .profile-infos-container {
                margin-top: 30px;

                .profile-name {
 
                }

                .member-infos {
    
                }

                .shuffle-favorites-container {
               

                    button {
 
                    }
                }
            }
        }

        .content-navbar-container {
            padding: 0 32px 8px;
            // border-bottom: 1px solid #EAEAEA;
            justify-content: center;
            row-gap: 32px;
            flex-wrap: wrap;

            a {
                text-decoration: none;
                color: unset;
                position: relative;
            }

            a:hover::after {
                content: "";
                position: absolute;
                bottom: -9px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
                background-color: black;
            }

            .active:after {
                content: "";
                position: absolute;
                bottom: -9px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color !important;
            }
        }

        .profile-stats-container {
            text-align: left;
            margin: 40px;
        }
    }

}