@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gabarito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500&display=swap');

@import './base';
@import './_variables';
@import './pages/homepage';
@import './pages/gamepage';
@import './pages/collectionspage';
@import './pages/settings';
@import './pages/playingpage';
@import './pages/albumPage';
@import './pages/profile';
@import './pages/faqpage';
@import './components/common';
@import './components/playbar';
@import './components/topbar';
@import './components/leftbar';
@import './components/videoPlayer';
@import './components/favorites';
@import './components/songsWrapper';
@import './components/albumsWrapper';
@import './components/artistsWrapper';
@import './components/heroBanner';
@import './components/collectionBanner';
@import './components/gamesWrapper';
@import './components/gameFilters';


::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ececec;
    border-radius: 4px;
}

body {
    background-color: #fff;
    color: #14181c;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding-bottom: 200px;
    min-height: 100.1vh;
    overflow-x: hidden;
}

.site-wrapper {
    .main-content {
        margin-left: $leftbar-width;
        margin-right: 0;
        position: relative;
        transition: $layout-change-transition;
    }
    .main-content--player-enabled {
        margin-right: $rightbar-width;
    }
}


h1 {
    color: #000;
    line-height: 1;
}

.queue-container {
    width: 100%;
    position: fixed;
    bottom: 72px;
    left: 0;
    z-index: 26;
    height: 0;
    overflow: hidden;
    background-color: #fff;
    transition: .37s ease-in-out;
    display: flex;
    flex-direction: row;
    column-gap: 0;

    .queue-left-content {

        margin-left: 50px;
        position: sticky;
        top: 0;
        left: 0;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

        .queue-left-img-container {
            // width: auto; /*$queue-left-width;*/
            height: $queue-left-width;
            margin-bottom: 12px;
            margin-top: 45px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

        .queue-playing-title {
            font-weight: bold;
            font-size: 18px;
            width: $queue-left-width;
            line-height: 1.3;
            margin-bottom: 15px;
        }

        .queue-playing-game {
            width: $queue-left-width;
            font-size: 14px;
        }
    }
}

.queue-container--active {
    height: 100vh;
    overflow: scroll;
    padding: 120px 20px;
}


.queue-container--active::-webkit-scrollbar {
    display: none;
    // width: 4px;
}

.queue-container--active::-webkit-scrollbar-thumb {
    display: none;
}


/* layout changes */
@media only screen and (max-width: 1023px) {
    .site-wrapper .main-content {
        margin: 0;
        width: 100%;
        /*yy*/
    }

    .site-wrapper .main-content--player-enabled {
        margin-top: calc(200px + $topbar-height + 12px);
    }

    h1 {
        margin-top: 27px;
        font-size: 24px;
    }

    .top-bar-container {
        // display: none;
        width: 100%;
        margin-left: 0;
    }

    /* queue songs wrapper */
    .queue-left-content {
        display: none;
    }
}


#new-tab {
    fill: #9ab;
    width: 11px;
    height: 11px;
    margin-bottom: 4px;
    margin-left: 4px;
}

