.collections-wrapper {
    // min-height: 360px;
    margin: 40px 45px;
    box-sizing: border-box;

    h1 {
        color: #000;
        font-size: 18px;
        text-align: left;
        margin: 40px 0 16px;
    }

    .result-btns {
        position: relative;
        justify-content: flex-start;
        padding: 0 20px;
    }

    .collections-container {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        row-gap: 20px;
        flex-wrap: wrap;

        a {
            text-decoration: none;
            color: #000;
        }

        .collection-wrapper {
            width: 270px;
            position: relative;

            .collection-popup-container {
                bottom: -80px;
                width: 250px;
            }

            .collection-image-wrapper {
                width: 100%;
                height: 140px;
                border-radius: 4px;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                margin-bottom: 4px;
                // overflow: hidden;
                // transition: background-size .1s ease; 

                .collection-btns-container {

                    .love-btn-container,
                    .options-btn-container {
                        opacity: 0;
                        transition: .1s ease-in-out;
                    }
                }

            }


            .collection-image-wrapper:hover .love-btn-container,
            .collection-image-wrapper:hover .options-btn-container {
                opacity: 1;
            }

            .collection-image-wrapper:after {
                background-color: #fff;
                bottom: 0;
                content: "";
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition-duration: .15s;
                transition-property: opacity, background-color;
            }

            .collection-image-wrapper:hover::after {
                opacity: .2;
            }

            .collection-name-container {
                font-size: 18px;
                padding-top: 0px;
                padding-left: 4px;
                text-align: left;
                font-family: 'Cabin Condensed', sans-serif;
                line-height: 1.5;
                margin-bottom: 2px;
                cursor: default;
            }

            .collection-infos {
                text-align: left;
                line-height: 1;
                padding-left: 4px;
                padding-bottom: 5px;
                font-size: 11px;
                font-family: 'Noto Sans Bengali', sans-serif;
                color: rgb(180, 180, 180);
                cursor: default;

                span {
                    // color: $primary-color;
                    color: #000;
                }
            }

        }

        .empty-collection-container {
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 140px;
            font-size: 90px;
            color: #ccc;
            transition: .2s ease-in-out;
            cursor: pointer;
            // padding-bottom: 20px;
            border-radius: 4px;

            .add-container {
                margin-top: 20px;
            }

        }

        .empty-collection-container:hover {
            font-size: 100px;
        }
    }

}

@media only screen and (max-width: 1023px) {

    .collections-wrapper {
        min-height: 360px;
        margin: 40px auto;
        box-sizing: border-box;

        h1 {
            color: #000;
            font-size: 18px;
            text-align: left;
            margin: 40px auto 16px;
            width: 90%;
            text-align: left;
        }

        .result-btns {
            position: relative;
            justify-content: flex-start;
        }

        .collections-container {
            display: flex;
            flex-direction: row;
            column-gap: 16px;
            row-gap: 20px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            a {
                text-decoration: none;
                color: #000;
            }

            .collection-wrapper {
                // width: 270px;
                width: 90%;
                max-width: 360px;
                margin: 0 auto;
                margin: 0;
                position: relative;

                .collection-popup-container {
                    bottom: -80px;
                    width: 250px;
                }

                .collection-image-wrapper {
                    width: 100%;
                    // height: 140px;
                    height: unset;
                    aspect-ratio: 1.928;
                    border-radius: 4px;
                    background-size: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    margin-bottom: 4px;
                    // overflow: hidden;
                    // transition: background-size .1s ease; 

                    .collection-btns-container {

                        .love-btn-container,
                        .options-btn-container {
                            opacity: 1;
                            transition: .1s ease-in-out;
                        }
                    }

                }


                .collection-image-wrapper:hover .love-btn-container,
                .collection-image-wrapper:hover .options-btn-container {
                    opacity: 1;
                }

                .collection-image-wrapper:after {
                    background-color: #fff;
                    bottom: 0;
                    content: "";
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition-duration: .15s;
                    transition-property: opacity, background-color;
                }

                .collection-image-wrapper:hover::after {
                    opacity: .2;
                }

                .collection-name-container {
                    font-size: 18px;
                    padding-top: 0px;
                    padding-left: 4px;
                    text-align: left;
                    font-family: 'Cabin Condensed', sans-serif;
                    line-height: 1.5;
                }

                .collection-infos {
                    text-align: left;
                    padding-left: 4px;
                    line-height: 1;
                    font-size: 12px;
                    color: #9ab;
                }

            }

            .empty-collection-container {
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                display: flex;
                height: 140px;
                font-size: 90px;
                color: #ccc;
                transition: .2s ease-in-out;
                cursor: pointer;
                // padding-bottom: 20px;
                border-radius: 4px;

                .add-container {
                    margin-top: 20px;
                }

            }

            .empty-collection-container:hover {
                font-size: 100px;
            }
        }

    }

}