button {
    outline: none;
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
    padding: 8px 25px;
    background-color: $primary-color;
    color: #fff;
    font-size: 15px;
    border-radius: 16px;
    margin: 10px 0px 36px;
    transition: .12s ease-in-out;
}

.not-selected-btn {
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
}

#svg--checked,
#svg-liked,
#svg-enabled,
#liked-album-svg,
#liked-collection-svg {
    fill: $primary-color;
    opacity: 1;
}

#view-youtube {
    fill: #FF0000;
    transition: .12s ease-in-out;
}

#hide-youtube {
    fill: #000;
    transition: .12s ease-in-out;
}

/* forms start */
.form-group {
    .label-category {
        font-size: 17px;
        margin-right: 5px;
    }

    span {
        margin-left: 4px;
        color: #9ab;
        font-size: 14px;
    }

    .span-shortcut {
        margin-left: 7px;
    }
}

button:disabled {
    opacity: .3;
    cursor: not-allowed;
}

.common-form-btns {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;
    font-family: 'Cabin Condensed', sans-serif;

    button:disabled {
        opacity: .3;
        cursor: not-allowed;
    }
}


form {
    height: 360px;
    position: relative;
    // text-align: center;
    text-align: left;
    font-family: 'Cabin Condensed', sans-serif;

    button.btn-primary {
        background-color: $primary-color;
        border: none;
    }

    button.btn-primary:hover {
        background-color: $primary-color;
        opacity: .85;
    }

    .common-form-btns {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        column-gap: 20px;
    }

    .edit-choose-btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        margin-top: 70px;

        button {
            width: 300px;
            margin: 0;
            font-size: 18px;
            border-radius: 20px;
        }
    }

    .drag-image-wrapper {
        display: flex;

        .drag-info-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 180px;
            text-align: center;
            width: 55%;
            margin: 0;
            padding: 20px;
            border: 2px dashed #ccc;
        }

        .manual-file-select-container {
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
        }
    }
}

.songs-wrapper form {
    height: 310px;
}

.option-blocked {
    cursor: not-allowed !important;
    opacity: .2;
    pointer-events: none;
}

.Toastify__toast-container {
    width: 360px;
}

.hidden {
    display: none !important;
}


/* album + game  + collection btns */
.component-btns-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    position: absolute;
    left: 15px;
    bottom: 14px;

    .component-btn-container {
        border-radius: 50%;
        background-color: #eee;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    .component-btn-container:hover {
        background-color: #ccc;
    }

}

@media only screen and (max-width: 1023px) {

    .mobile-hidden {
        display: none !important;
    }

    .component-btns-container {
        left: 15px;
        bottom: 14px;
    }
}

/* popup on albums/games/collections */
.component-popup-container {
    position: absolute;
    bottom: 0;
    left: 0px;
    background-color: #F8F8F9;
    padding: 10px 12px;
    width: 125%;
    z-index: 10;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .24);
    cursor: default;

    ul {
        margin: 0;
        padding: 0;

        li {
            padding: 13px 18px;
            list-style: none;
            transition: .2s ease-in-out;
            border-radius: 10px;
            font-size: 16px;
            color: #131313;
            cursor: pointer;
        }

        li:hover {
            background-color: #eee;
        }
    }
}


/* canvas stuff */
canvas,
#palette,
#complementary {
    display: none;
}

/* tags in banners */

.tag {
    padding: 4px 10px 2px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: default;
}

/* add to collection popup for album & game result box */
.add-to-collection-popup {
    overflow: hidden;
    min-height: 254px;

    input {
        display: block;
        border: 2px solid #9ab;
        font-size: 15px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 0;
        background-color: transparent;

    }

    #find-collection {
        // li 
        margin: 5px auto 7px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: #eee;
    }
}

/* add mix and my albums/collections only btn */
/*
.result-btns {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    button {
        font-size: 14px;
    }
}
*/

.pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 50px;

    .pagination-btn {
        font-size: 12px;
    }

    .pagination-btn:disabled {
        opacity: .3;
    }
}