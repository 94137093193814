// rightbar actually

.right-bar {

    height: calc(100vh - 72px);
    box-sizing: border-box;
    background-color: #F8F8F9;
    position: fixed;
    right: 0;
    top: 0;
    border-right: 1px solid #EAEAEA;
    text-align: left;
    padding-left: $video-padding;
    padding-right: $video-padding;
    box-sizing: border-box;

    // display: none;
    width: 0px;
    overflow: hidden;
    // opacity: 0;
    visibility: hidden;
    transition: $layout-change-transition;

    display: flex;
    align-items: center;

    .game-info-container {
        margin-top: 20px;
        text-align: center;

        img {
            max-width: 140px;
            max-height: 140px;
            border-radius: 6px;
        }

        h1 {
            font-size: 16px;
            line-height: 1.2;
            margin: 5px 20px 10px;
        }
    }


    .youtube-iframe-container {

        transition: .2s ease-in-out;
        z-index: 100; // del later maybe, was causing issues on mobile?
        text-align: center;
        margin: 10px auto;
        box-sizing: border-box;
        // overflow-x: hidden;

        iframe {
            box-sizing: border-box;
            border-radius: 6px;
            object-fit: contain;
            max-height: 210px;
            /* 210 */
            max-width: calc($rightbar-width - $video-padding - $video-padding - 4px);
            margin: 0 auto;
        }

        .youtube-iframe-filler {
            position: absolute;
            z-index: 101;
            opacity: 0;
            left: 0;
            top: 0;
            // width: 420px;
            // height: 280px;
            display: none;
            /* removed feature */
        }
    }
}

.right-bar--active {
    // opacity: 1;
    // display: block;
    visibility: visible;
    width: $rightbar-width;
    overflow-y: hidden;
    overflow-x: scroll;
    z-index: 125;
}



@media only screen and (max-width: 1023px) {

    .right-bar {

        width: 100%;
        height: 200px;
        position: fixed;
        right: unset;
        top: calc($topbar-height);
        padding-top: 12px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        box-sizing: content-box;

        /* not sure
        border-right: 1px solid #EAEAEA;
        text-align: left;
        padding-left: $video-padding;
        padding-right: $video-padding;
        display: flex;
        align-items: center;
        */

        .youtube-iframe-container {

            iframe {
                width: 100%;
                height: 200px;
            }

            .youtube-iframe-filler {}
        }
    }

    .right-bar--active {}

}