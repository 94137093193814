.game-filters-wrapper {
    margin-bottom: 50px;

    .switches-container {

        margin-bottom: 30px;
        column-gap: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 25px;

        .toggle-container {
            display: flex;
            align-items: center;

            .react-switch {
                margin-right: 5px;
            }

            span {
                font-size: 14px;
                font-family: Roboto;
            }
        }
    }

    h2 {
        margin-bottom: 10px;
        font-size: 17px;
        margin-left: -2px;
        font-family: Roboto;
        line-height: 1;
    }

    .game-filters-group {

        margin-bottom: 30px;

        // flex container
        .game-filters-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 20px;
            // column-gap: 50px;
            row-gap: 2px;

            input {
                width: 15px;
                height: 15px;
                margin-right: 2px;
            }

            input[type="checkbox"] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                transition: .12s ease-in-out;
                border: 2px solid rgb(205, 205, 205);
                border-radius: 50%;
                margin-right: 4px;
                margin-bottom: -1px;
                cursor: pointer;
            }

            input[type="checkbox"]:checked {
                background-color: $primary-color;
                border: 2px solid $primary-color;
            }

            div.label-container {
                width: 165px;
                /*165*/
                display: inline-block;

                label {
                    font-family: 'Cabin Condensed', sans-serif;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }

        /* custom slider */
        .multi-range-slider {
            max-width: 600px;
            width: 100%;
            box-shadow: none;
            border: none;

            .labels {
                display: none;
            }

            .bar-left,
            .bar-right {
                box-shadow: none;
                background-color: rgb(218, 218, 218) !important;
            }

            .bar-inner {
                background-color: #ef5466c8 !important;
                box-shadow: none;
            }

            .thumb::before {
                background-color: #F8F8F9;
                width: 17px;
                height: 17px;
                bottom: 4px;
                box-shadow: none;
                // background-color: orange;
                // border: none;
            }

            .thumb .caption {
                display: inline-flex;
            }

            .thumb .caption * {
                background-color: #F8F8F9;
                color: black;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 0;
                padding-bottom: 0;
                border-radius: 0;
                box-shadow: none;
                height: unset;
                font-size: 11px;
                bottom: -25px;
                background-color: transparent;
                left: -26px;
            }

            .min-caption {
                bottom: -66px !important;
            }

        }

    }

}

@media only screen and (max-width: 1023px) {
    .game-filters-wrapper {


        .switches-container {


            .toggle-container {


                .react-switch {}

                span {}
            }
        }

        .game-filters-group {

            // flex container
            .game-filters-container {

                // column-gap: 40px;
                div.label-container {
                    width: 140px; /*165*/

                    label {}

                }
            }

            /* custom slider */
            .multi-range-slider {

                .labels {}

                .bar-left,
                .bar-right {}

                .bar-inner {}

                .thumb::before {}

                .thumb .caption {}

                .thumb .caption * {}

                .min-caption {}

            }

        }

    }
}