.games-wrapper {
    
    .common-form-btns {
        justify-content: flex-start;
        position: relative;
        text-align: left;
        margin: 0;
    }

    .game-results {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        row-gap: 30px;
        flex-wrap: wrap;
        justify-content: flex-start;


        .game-container {
            // width: 147px;
            width: 176px;
            position: relative;
            // height: 300px;


            .game-popup-container {
                position: absolute;
                bottom: 0;
                left: 0px;
                background-color: #F8F8F9;
                padding: 10px 12px;
                width: 125%;
                /* may remove later */
                z-index: 10;
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .24);
                min-width: 240px;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        padding: 13px 18px;
                        list-style: none;
                        transition: .2s ease-in-out;
                        border-radius: 10px;
                        font-size: 16px;
                        color: #131313;
                        cursor: pointer;
                    }

                    li:hover {
                        background-color: #eee;
                    }
                }
            }


            .svg-container {
                border-radius: 50%;
                background-color: white;
                width: 28px;
                aspect-ratio: 1;
                z-index: 2;
                position: absolute;
                right: 10px;
                top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                opacity: 0;
                transition: .1s ease-in-out;

                svg {
                    width: 12px;
                }

                #liked-game-svg {
                    fill: $primary-color;
                }
            }

        }

        .game-container:hover .svg-container {
            opacity: 1;
        }

        .game-cover {
            aspect-ratio: 0.742;
            width: 100%;
            border-radius: 6px;
            background-size: cover;
            cursor: pointer;
            margin-bottom: 7px;
            position: relative;

            .game-btns-container {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                position: absolute;
                left: 15px;
                bottom: 14px;
                transition: .1s ease-in-out;
                opacity: 0;
                z-index: 5;

                .game-btn-container {
                    border-radius: 50%;
                    background-color: #eee;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 15px;
                        height: 15px;
                    }

                    #liked-game-svg {
                        fill: $primary-color;
                    }
                }

                .game-btn-container:hover {
                    background-color: #ccc;
                }
            }

        }

        .game--empty {
            opacity: .25;
        }
        /*
        .game--empty::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: black;
            opacity: 0.5;
        }
        */

        .game-cover:hover .game-btns-container {
            opacity: 1;
        }

        .game-cover:after {
            background-color: #fff;
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition-duration: .1s;
            transition-property: opacity, background-color;
        }

        .game-cover:hover::after {
            opacity: .08;
        }

        .game-title-text {
            width: 100%;
            cursor: pointer;
            text-decoration: none;
            color: #000;
            font-size: 13px;
            font-family: 'Noto Sans Bengali', sans-serif;
            line-height: 1.3;
        }
    }
}

.games-wrapper-simplified {

    display: block;
    margin: 0;
    padding: 0 36px;
    margin-top: 16px;

    .game-container-simplified {
        width: 100%;
        text-align: left;
        display: block;
        padding: 8px;
        border: 1px solid #ccc;
        font-size: 14px;
        margin-bottom: 12px;
        transition: .5s ease-in-out;
        cursor: grab;

        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;

    }
}



@media only screen and (max-width: 1023px) {

    .games-wrapper {
        width: 100%;
        margin-left: 0;
        padding: 0 16px;

        p.no-games {}

        h1 {
            text-align: left;
            margin: 40px 0 16px;
        }

        .game-results {
            justify-content: center;
            column-gap: 16px;

            .game-container {
                width: 46.5%;
                max-width: 176px;

                .svg-container {

                    width: 28px;
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    opacity: 1;

                    svg {
                        width: 12px;
                    }
                }

            }

            .game-container:hover .svg-container {}

            .game-cover {
                margin-bottom: 6px;

                .game-btns-container {
                    opacity: 1;

                    .game-btn-container {
                        width: 40px;
                        height: 40px;

                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    .game-btn-container:hover {}
                }
            }


            .game-cover:after {}

            .game-cover:hover::after {}

            .game-title-text {}
        }
    }
}