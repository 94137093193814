.playing-container--page {
    width: 100vw;
    // height: calc(100vh - 70px); 
    height: calc(100vh - 70px - $topbar-height);
    overflow: hidden;
    color: #fff;
    z-index: 36; /* !! this blocks the leftbar buttons on mobile */
    position: fixed;
    bottom: 0;
    left: 0;

    .playing-top-bar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // margin-bottom: 40px;

        div {
            font-size: 10px;
            color: #000;
            font-family: Roboto;
        }

    }

    .playing-flex-container {

        padding: 25px 0 0;
        height: calc(100vh - 165px); //-125
        height: calc(100vh - 165px - $topbar-height);
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        box-sizing: border-box;


        .playing-album-cover {
            border-radius: 8px;
            margin: 0 auto;
            aspect-ratio: 1;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            min-width: 150px;
            min-height: 150px;

            // max-width: 200px;
            // max-height: 200px;

            flex: 1;
            overflow: hidden;
            /* Ensure content doesn't overflow */
            // margin-bottom: 50px;

        }

        .new-content-container {

            .playing-btns-container--first {

                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 32px;
                margin-bottom: 20px;
                margin-top: 6px; /* just to put more space between album and btns*/

                .playback-option-btn {
                    transition: .05s ease-in-out;
                    cursor: pointer;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    padding-top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $primary-color;

                    svg {
                        width: 25px;
                        height: 25px;
                    }
                }

                .playback-option-btn:hover:not(.playback-option-btn--sound) {
                    background-color: #eee;
                }

            }

            .playing-progress-container {
                height: 16px;
                width: 86%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                font-size: 10px;
                margin: 0 auto 24px;
                position: relative;

                .current-duration,
                .total-duration {
                    padding-top: 0px;
                    color: #000;
                    margin-top: -24px;
                    position: absolute;
                }

                .current-duration {
                    padding-right: 0;
                    left: 0px;
                }

                .total-duration {
                    padding-left: 0;
                    right: 0px;
                }

                #progress-container {
                    width: 100%;
                    height: 3px; // changed 
                    border-radius: 5px;
                    background-color: #000;
                    margin-top: 0px;
                    overflow: hidden;

                    #progress-bar {
                        width: 0;
                        height: 100%;
                        background: linear-gradient(1deg, #d32f2f 13%, #f6693c);
                        transition: width 0.2s;
                    }

                }
            }

            .playing-text-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column-reverse;
                row-gap: 2px;
                width: 100%;
                margin-bottom: 18px;
                color: #000;

                a {
                    text-decoration: none;
                    color: #000;
                }
                .playing-title {
                    font-size: 18px;
                    font-weight: bold;
                    max-height: 26px; /* to fix the 2 line problem */
                    overflow: hidden;
                    width: 86%;
                }
                .playing-game {
                    width: 86%;
                }
                .playing-game a {
                    display: inline-block;
                    font-size: 13px;
                    max-height: 19px; /* to fix the 2 line problem */
                    overflow: hidden;
                }

            }

            .playing-play-controls {
                padding: 40px 0;
                box-sizing: border-box;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: row;

                .play-control {
                    width: 28px;
                }

                .play-control.play {
                    width: 32px;
                }

            }

        }

    }


}


@media only screen and (min-width: 769px) {
    .playing-container--page {
        display: none;
    }
}