.songs-wrapper {
    // margin: 40px 70px;
    margin: 0;
    padding: 40px 70px;

    box-sizing: border-box;
    position: relative;

    width: 100%; // nn

    // common in 2 forms
    .edit-btns {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        column-gap: 20px;

        .remove-from-album {
            background-color: darkred;
            display: inline-flex;
            flex-direction: row;
            column-gap: 8px;
            padding-left: 16px;
            span {
                padding-top: 1px;
            }
        }
    }

    .container-settings {
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 14px;
        column-gap: 16px;
        margin-bottom: 10px;

        input {
            margin-top: 2px;
            width: 250px;
            height: 34px;
            font-size: 13px;
            font-family: 'Noto Sans Bengali', sans-serif;
        }

        .option {
            border-radius: 50%;
            aspect-ratio: 1;
            padding-top: 4px;
            cursor: pointer;
            width: 30px;
            height: 30px;
            color: #000;
            font-size: 14px;
            transition: .05s ease-in-out;

            svg {
                box-sizing: content-box;
                width: 16px;
                cursor: pointer;
            }
        }

        .option--checkbox {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        .option--checkbox[toggle-mode="enable"] {
            .svg--checked {
                display: none;
            }
        }

        .option--checkbox[toggle-mode="disable"] {
            .svg--not-checked {
                display: none;
            }
        }
    }

    .song-edit-form-container--multi {
        width: 100%;
        text-align: left;
        padding: 25px 30px;
        margin: 10px 0 60px;
        border: 1px solid rgb(191, 191, 191);
        display: none;

        .applied-songs-info {
            color: #000;
            margin-bottom: 10px;
            font-size: 14px;

            .info-header {
                margin-bottom: 10px;
            }
        }

        input {
            padding: 5px 10px;
            font-size: 13px;
            margin: 10px 0;
            display: block;
            width: 50%;
            max-width: 250px;
        }

        input:focus {
            outline: none;
        }

    }

    .song-edit-form-container--multi--active {
        display: block;
    }

    .song-old-name {

        span {
            margin: 0 4px;
        }

        .song-new-name {
            color: green;
            // font-weight: bold;
            display: inline-block;
        }

    }

    .queue-toggle-btn {
        display: none;
    }

    .songs-container {
        display: flex;
        row-gap: 0px;
        width: 100%;
        font-size: 17px;
        color: #fff;
        flex-direction: column;
        padding-bottom: 60px;

        /* needed for queue */

        .hidden {
            display: none;
        }

        .song-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            padding: 8px 14px;
            margin: 0;
            border-radius: 7px;
            height: 36px;
            box-sizing: content-box;

            .song-img-container {
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    cursor: pointer;
                    aspect-ratio: 1;
                    height: 100%;
                    border-radius: 3px;
                    object-fit: cover;
                }

                img:hover .svg-container {
                    opacity: 1;
                }

                .svg-container {

                    .playing-gif-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .1s ease-in-out;
                        cursor: pointer;
                        z-index: 2;
                        opacity: 1;
                        margin: 0 auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        width: 90%;
                        height: 90%;
                        background-color: #fff;
                        padding-top: 1px;
                        padding-left: 1px;

                        img.playing-gif {
                            width: 12px;
                            height: auto;
                        }
                    }

                    .svg-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .1s ease-in-out;
                        cursor: pointer;
                        z-index: 2;
                        opacity: 1;
                        margin: 0 auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        width: 90%;
                        height: 90%;
                        background-color: #fff;
                        opacity: 0;

                        svg {
                            width: 13px;
                            height: 13px;
                        }
                    }

                    .svg-content.no-hide {
                        opacity: 1;
                    }

                    .svg-continue-playback-container {
                        display: block;
                        opacity: 1;
                    }

                }

            }

            .song-title-container {
                margin-left: 18px;
                width: 100%;
                text-align: left;

                .song-title {
                    width: 100%;
                    padding-right: 40px;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 14px;
                    text-align: left;
                    color: #000;
                    text-decoration: none;

                }

                .song-title--active {
                    color: $primary-color;
                }

                .song-title:hover {
                    text-decoration: underline;
                }
            }

            img:hover {
                opacity: 0.85;
            }

            .song-options {
                height: 100%;
                // width: 220px; /*300*/
                padding-top: 2px;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                column-gap: 16px;

                .option {
                    border-radius: 50%;
                    aspect-ratio: 1;
                    padding-top: 4px;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    color: #000;
                    font-size: 14px;
                    transition: .05s ease-in-out;

                    svg {
                        box-sizing: content-box;
                        width: 16px;
                        cursor: pointer;
                    }
                }

                /* .option-blocked moved to common */

                .option--duration {
                    cursor: default;
                    margin-right: 40px;
                }

                .option--checkbox svg {
                    width: 20px;
                    height: 20px;
                }

                .option--checkbox {
                    border-radius: 0;

                    .svg--checked {
                        transition: opacity .3s ease;
                        opacity: 1;
                        visibility: visible;
                    }

                }

                .option-checkbox--checked {
                    .svg--checked {
                        display: block;
                        width: 22px;
                        height: 22px;
                        margin-left: 4px;
                        opacity: 1;
                        visibility: visible;
                    }

                    .svg--not-checked {
                        display: none;
                    }
                }

                .option:not(.option--duration, .option--checkbox):hover {
                    background-color: #dedede;
                }

                #liked-song-svg {
                    fill: $primary-color;
                }

            }
        }

        .song-container:hover {
            // background-color: #f8f8f9;
            background-color: #eaeaea;
        }

        .song-container:hover .song-img-container .svg-container .svg-content {
            opacity: 1 !important;
        }

        .playing:before {
            background-image: url(https://m.media-amazon.com/images/G/01/digital/music/player/web/EQ_accent.gif);
            background-size: contain;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70%;
            filter: grayscale(100%);
            opacity: 0.07;
        }

        .song-edit-form-container {
            width: 100%;
            text-align: left;
            border: none;
            display: none;
            position: relative;

            .applied-songs-info {
                color: #000;
                margin-bottom: 10px;
                font-size: 14px;

                .info-header {
                    margin-bottom: 10px;
                }
            }

            input {
                padding: 5px 10px;
                font-size: 13px;
                margin: 10px 0;
                display: block;
                width: 50%;
                max-width: 250px;
            }

            input:focus {
                outline: none;
            }


        }

        .song-edit-form-container--active {
            padding: 25px 30px;
            margin: 10px 0 60px;
            border: 1px solid rgb(191, 191, 191);
            display: block;
        }


        .add-song-empty-container {
            .song-img-container {
                opacity: 0;
            }

            .song-title-container .song-title:hover {
                text-decoration: none;
            }
        }
    }

}

.songs-wrapper--queue-visible {
    margin-right: $rightbar-width; /* only if rightbar is enabled and its queue page */
}

@media only screen and (max-width: 1023px) {

    .songs-wrapper {
        // margin-right: 0;
        // margin-left: 0;
        padding-left: 0;
        padding-right: 0;

        .queue-toggle-btn {
            display: block;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            width: 36px;
        }

        .container-settings {
            .option {
                display: none;
            }
        }

        .songs-container {

            .song-container {
                padding: 8px 14px;
                height: 48px;
                /* 36 */

                .song-img-container {

                    .svg-container {

                        .playing-gif-container {
                            width: 90%;
                            height: 90%;

                            img.playing-gif {
                                width: 12px;
                            }
                        }

                        .svg-content {
                            width: 90%;
                            height: 90%;
                            opacity: 0;

                            svg {
                                width: 13px;
                                height: 13px;
                            }
                        }
                    }
                }

                .song-title-container {
                    margin-left: 18px;

                    .song-title {
                        padding-right: 40px;
                        /* 40 */
                        margin-top: 3px;
                        line-height: 1.25;
                    }

                    .song-title:hover {
                        text-decoration: underline;
                    }
                }

                .song-options {
                    width: unset;
                    padding-top: 2px;
                    column-gap: 16px;

                    .option {
                        padding-top: 4px;
                        width: 30px;
                        height: 30px;
                        display: none;

                        svg {
                            width: 16px;
                        }
                    }

                    .option--like {
                        display: block;
                        padding-right: 10px;
                    }

                    .option--duration {
                        margin-right: 40px;
                    }

                    .option--checkbox svg {
                        width: 20px;
                        height: 20px;
                    }

                    .option-checkbox--checked {
                        .svg--checked {
                            width: 22px;
                            height: 22px;
                            margin-left: 4px;
                        }

                    }

                }
            }

            .add-song-empty-container {
                display: none;
            }

            .playing:before {
                width: 100%;
                height: 70%;
            }

            .song-edit-form-container {
                width: 100%;

                .applied-songs-info {
                    margin-bottom: 10px;

                    .info-header {
                        margin-bottom: 10px;
                    }
                }

                input {
                    padding: 5px 10px;
                    margin: 10px 0;
                    width: 50%;
                    max-width: 250px;
                }

            }
        }

    }
    
    .songs-wrapper--queue-visible {
        margin-right: 0; /* only if rightbar (video) is enabled AND its queue page */
        padding-top: calc(40px + 200px + $topbar-height); // default padding + vid height

        .queue-toggle-btn { 
            top: calc(40px + 200px + $topbar-height);
        }
    }

    .songs-wrapper--queue { // queue, but no video.
        padding-top: calc(40px + $topbar-height);

        .queue-toggle-btn { 
            top: calc(40px + $topbar-height);
        }

    }
}