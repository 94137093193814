.flex-wrapper {
    display: flex;
    // flex-flow: row nowrap;
    flex-flow: row wrap;
    margin-bottom: 100px;
    width: 100%;
    max-width: 760px;
    /* 600 */
    row-gap: 40px;
    column-gap: 30px;
}

.single-chart {
    // width: 33%;
    // justify-content: space-around;
    flex-direction: row; // new
    display: flex; // new
    align-items: center;
    width: 33%;
    max-width: 230px;
    // background-color: yellow;
    column-gap: 15px;
    justify-content: flex-start;

    svg {
        margin: 0;
    }

    .circle-text-info-container {

        max-width: unset;
        // background-color: red;

        span {
            display: block;
            font-family: 'Noto Sans Bengali', sans-serif;
            text-align: center;
        }

        .info-title {
            font-size: 13px;
            font-weight: bold;

        }

        .info-value {
            font-size: 13px;

        }
    }


}

.circular-chart {
    display: block;
    margin: 10px auto;
    // max-width: 80%;
    max-height: 250px;
    max-width: 80px; // new
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1.5s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}


/* new values */
.circular-chart.color-val-1 .circle {
    stroke: #bbbbbb;
}

.circular-chart.color-val-2 .circle {
    stroke: #cb891e;
}

.circular-chart.color-val-3 .circle {
    stroke: #e3be09;
}

.circular-chart.color-val-4 .circle {
    stroke: #f4d227;
}

.circular-chart.color-val-5 .circle {
    stroke: #2dc715;
}

.circular-chart.color-val-6 .circle {
    stroke: #42FF00;
}

.circular-chart.color-val-7 .circle {
    stroke: turquoise
}

/* new values end */

.percentage {
    font-size: 0.46em;
    text-anchor: middle;
    // font-family: sans-serif;
    // fill: $primary-color;
    // font-family: 'Noto Sans Bengali', sans-serif;
    font-family: 'Cabin Condensed', sans-serif;
    fill: #232323;
}


@media only screen and (max-width: 1023px) {

    .flex-wrapper {
        display: flex;
        // flex-flow: row nowrap;
        flex-flow: row wrap;
        margin-bottom: 100px;
        width: 100%;
        max-width: 760px;
        /* 600 */

    }

    .single-chart {
        width: 100%;
        margin: 0 auto;
        max-width: 300px;

        /*230*/
        justify-content: flex-start;
        // background-color: yellow;

        svg {}

        .circle-text-info-container {

            // max-width: unset;

            span {
                display: block;
                font-family: 'Noto Sans Bengali', sans-serif;
                text-align: center;
            }

            .info-title {
                font-size: 13px;
                font-weight: bold;
                text-align: left;

            }

            .info-value {
                font-size: 13px;
                text-align: left;

            }
        }


    }

    .circular-chart {
        display: block;
        margin: 10px auto;
        // max-width: 80%;
        max-height: 250px;
        max-width: 80px; // new
    }

    .circle-bg {
        fill: none;
        stroke: #eee;
        stroke-width: 3.8;
    }

    .circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        animation: progress 1.5s ease-out forwards;
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }


    /* new values */
    .circular-chart.color-val-1 .circle {
        stroke: #bbbbbb;
    }

    .circular-chart.color-val-2 .circle {
        stroke: #cb891e;
    }

    .circular-chart.color-val-3 .circle {
        stroke: #e3be09;
    }

    .circular-chart.color-val-4 .circle {
        stroke: #f4d227;
    }

    .circular-chart.color-val-5 .circle {
        stroke: #2dc715;
    }

    .circular-chart.color-val-6 .circle {
        stroke: #42FF00;
    }

    .circular-chart.color-val-7 .circle {
        stroke: turquoise
    }

    /* new values end */

    .percentage {
        font-size: 0.46em;
        text-anchor: middle;
        // font-family: sans-serif;
        // fill: $primary-color;
        // font-family: 'Noto Sans Bengali', sans-serif;
        font-family: 'Cabin Condensed', sans-serif;
        fill: #232323;
    }

}