.album-banner-container {
    width: 100%;
    padding: 40px 60px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    align-content: center;
    flex-direction: row;
    column-gap: 36px;

    .love-btn-container {
        display: inline-block;
    }

    svg {
        width: 21px;
        margin-top: 10px;
        cursor: pointer;
    }

    .album-banner-container-left {
        min-height: 240px;
        display: flex;
        align-items: center;

        .album-cover {
            width: 240px;
            border-radius: 6px;
        }
    }

    .album-banner-container-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-family: 'Noto Sans Bengali', sans-serif;
        text-align: left;
        width: 100%;
        padding-right: 20px; /* 100 */
        box-sizing: border-box;

        .banner-tags-container {

            display: flex;
            flex-direction: row;
            column-gap: 10px;
            margin-bottom: 8px;

            .tag {
                background-color: $primary-color;
                color: #fff;
            }
        }

        .album-title {
            font-size: 32px;
            line-height: 1.23;
            margin-bottom: 16px;
            font-family: 'Cabin Condensed', sans-serif;
        }

        .album-additional-infos {
            font-size: 14px;
            margin-bottom: 5px;
            color: #3d4d5c;
        }

        .album-added-by {
            font-size: 14px;

            a {
                text-decoration: none;
            }
            span {
                cursor: pointer;
                font-weight: bold;
                font-size: 13px;
                color: $primary-color;
            }

        }

        .album-last-updated {
            font-size: 14px;
            margin-top: 2px;

            span {
                color: #3d4d5c;
                font-size: 13px;
            }
        }

        .album-description {
            max-height: 80px;
            overflow-y: scroll;
            margin-top: 30px;
            color: #3d4d5c;
            /* firefox scrollbar */
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;

            .line {
                font-size: 13px;
            }
        }

        .album-description::-webkit-scrollbar {
            width: 0;
        }

        .album-description::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        .album-banner-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;   
            margin-top: 10px;
            column-gap: 20px;

            button {
                margin: 0;
                font-family: 'Cabin Condensed', sans-serif;
            }
            svg {
                margin-top: 0;
            }
        }

    }

}

@media only screen and (max-width: 1023px) {

    .album-banner-container {
        width: 100%;
        padding: 40px 60px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        align-content: center;
        flex-direction: column;
        column-gap: 36px;

        .album-banner-container-left {
            min-height: 240px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            .album-cover {
                width: 240px;
                border-radius: 6px;
            }
        }

        .album-banner-container-right {
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-family: 'Noto Sans Bengali', sans-serif;
            text-align: left;
            width: 100%;
            padding-right: 0; // new
            box-sizing: border-box;


            .banner-tags-container {

                display: flex;
                flex-direction: row;
                column-gap: 10px;
                margin-bottom: 8px;
                text-align: center;
                justify-content: center;
                align-items: center;

                .tag {
                    background-color: $primary-color;
                    color: #fff;
                }
            }

            .album-title {
                text-align: center;
                font-size: 32px;
                line-height: 1.23;
                margin-bottom: 16px;
                font-family: 'Cabin Condensed', sans-serif;
            }

            .album-additional-infos {
                font-size: 14px;
                margin-bottom: 5px;
                color: #3d4d5c;
                text-align: center;
            }

            .album-added-by {
                font-size: 14px;
                text-align: center;

                span {
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 13px;
                    color: $primary-color;
                }
            }

            .album-last-updated {
                font-size: 14px;
                text-align: center;

                span {
                    color: #3d4d5c;
                    font-size: 13px;
                }
            }

            .album-description {
                max-height: 80px;
                overflow-y: scroll;
                margin-top: 30px;
                color: #3d4d5c;
                /* firefox scrollbar */
                scrollbar-width: thin;
                scrollbar-color: transparent transparent;

                .line {
                    font-size: 13px;
                }
            }

            .album-description::-webkit-scrollbar {
                width: 0;
            }

            .album-description::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            .album-banner-buttons {
                text-align: center;
                justify-content: center;
            }

        }

    }

}