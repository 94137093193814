.artists-container {
    min-height: 220px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    row-gap: 20px;
    margin: 28px 40px;


    .artist-wrapper {
        width: 180px;
        // border: 1px solid #ccc;
        cursor: pointer;

        .artist-image {
            border-radius: 8px;
            width: 100%;
            height: 250px;
            background-image: url(https://lastfm.freetls.fastly.net/i/u/ar0/2936597b0a3dc6f99c4466a8da082ee7.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .artist-image-1 {
            background-image: url(https://lastfm.freetls.fastly.net/i/u/ar0/6073cefedff28e99f13c49452bbd89a1.jpg);
        }

        .artist-image-2 {
            background-image: url(https://m.media-amazon.com/images/M/MV5BMTkyMDQwMDM5N15BMl5BanBnXkFtZTgwNTQ4MDgwNzE@._V1_FMjpg_UX1000_.jpg);
        }



        .artist-name {
            font-size: 12px;
            padding: 2px 3px;
            text-align: left;
        }



    }
}