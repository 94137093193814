.faq-page {
    width: 80%;
    margin: 72px auto;

    .accordion-item {
        border: none;
    }

    .accordion-body {
        color: $primary-color;
        color: #000;
        font-family: 'Roboto', sans-serif;
        text-align: left;
        font-size: 15px;
    }

    .accordion-header {
        box-shadow: none;
    }

    .accordion-button {
        margin: 8px auto; /*3px*/
        // background-color: $primary-color;
        background-color: #F8F8F9;
        // color: #fff;
        color: #000;
        font-family: 'Cabin Condensed', sans-serif;
        box-shadow: none;
        font-size: 16px;
        padding: 20px; /* 10 */
    }

    .accordion-button:after {
        // background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

}