.playbar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    cursor: default;
    background-color: #F8F8F9;
    border-top: 1px solid #EAEAEA;
    z-index: 26;
    /* bigger than topbar */


    .play-controls {
        width: 220px;
        height: 100%;
        display: flex;
        align-items: center;
        column-gap: 14px;
        padding-bottom: 8px;
        padding-left: 25px;
        box-sizing: border-box;

        .play-control {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            padding: 7px;
            box-sizing: content-box;
            border-radius: 50%;
            cursor: pointer;
        }

        .play-control:hover {
            background-color: #eee;
        }

        .play {
            width: 24px;
            height: 24px;
        }

    }

    .playback-info {
        height: 100%;
        width: 50%;
        padding: 15px 10px 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding-left: 35px;

        .first-row {
            height: 50%;
            margin: 0 33px;

            .playing-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;


                .playing-text {
                    display: flex;
                    flex-direction: row;
                    column-gap: 6px;
                    width: calc(100% - 40px);
                    overflow: hidden;

                    .playing-game {
                        text-align: left;
                        max-height: 21px;
                        /* 2 line fix */
                        overflow: hidden;
                        position: relative;
                        max-width: 50%;
                        white-space: nowrap;
                        text-overflow: ellipsis; 
                    }

                    .playing-title {

                        max-height: 21px;
                        /* 2 line fix */
                        overflow: hidden;
                        max-width: 50%;
                        position: relative;

                        white-space: nowrap;
                        text-overflow: ellipsis; 

                    }

                    .playing-title a,
                    .playing-game a {
                        text-decoration: none;
                        color: unset;
                        text-align: left;
                    }
                }

                .song-option-buttons {
                    width: 100px;
                    height: 20px;
                    float: right;
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row;
                    column-gap: 8px;
                    padding-bottom: 120px;

                    .song-option-btn {
                        transition: .05s ease-in-out;
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        padding-top: 3px;

                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        #liked-song-svg {
                            fill: $primary-color;
                        }
                    }

                    .song-option-btn:hover {
                        background-color: #eee;
                    }
                }
            }
        }

        .second-row {
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            font-size: 10px;

            .duration-info {
                width: 30px;
                overflow: hidden;
            }

            .current-duration,
            .total-duration {
                padding-top: 2px;
            }

            .current-duration {
                padding-right: 10px;
            }

            .total-duration {
                padding-left: 10px;
            }

            #progress-container {
                width: 100%;
                height: 2px;
                border-radius: 5px;
                background-color: #000;
                margin-top: 0px;
                overflow: hidden;

                #progress-bar {
                    width: 0;
                    height: 100%;
                    // background-color: rgb(85, 117, 187);
                    background: linear-gradient(1deg, #d32f2f 13%, #f6693c);
                    transition: width 0.2s;
                }

            }
        }
    }

    .playback-options {
        height: 100%;
        width: calc(50% - 220px);
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .playback-options-buttons {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 8px;

            .playback-option-btn--sound {
                position: relative;
                display: inline-block;
                padding: 10px;
                cursor: pointer;

                #volume-svg {
                    z-index: 500;
                }
            }

            .popover-wrapper {
                display: none;
                width: 58px;
                /*58*/
                height: 32px;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                z-index: 27;
                /* bigger than queue container */
                margin-left: 3px;

                .popover {
                    display: flex;
                    opacity: 0;
                    transition: .2s ease-in-out;
                    position: absolute;
                    padding: 10px;
                    z-index: 1;
                    top: -39px;
                    width: 220px;
                    height: 40px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #fcfcfc;
                    border: none;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

                    input {
                        width: 100%;
                        height: 3px;
                    }

                }

                input[type="range"] {
                    background: linear-gradient(to right, $primary-color 0%, $primary-color 50%, #ddd 50%, #ddd 100%);
                    border-radius: 2px;
                    height: 2px;
                    width: 100%;
                    outline: none;
                    transition: background 450ms ease-in;
                    -webkit-appearance: none;
                    cursor: pointer;
                }

                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 13px;
                    height: 13px;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    cursor: pointer;
                    margin-top: -1px;
                }

            }

            .playback-option-btn--sound:hover .popover,
            .popover-wrapper:hover .popover {
                opacity: 1;
            }

            .playback-option-btn--sound:hover .popover-wrapper {
                display: block;
            }

            .playback-option-btn {
                transition: .05s ease-in-out;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                padding-top: 0px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }

            .playback-option-btn:hover:not(.playback-option-btn--sound) {
                background-color: #eee;
            }


        }


        .playback-options--queue {
            height: 100%;
            margin: 0 24px 0 60px;
            display: flex;
            align-items: center;

            .queue-content-container {
                padding: 10px 16px 10px 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                column-gap: 9px;
                cursor: pointer;
                border-radius: 4px;
                transition: .1s ease-in-out;

                .queue-album-img {
                    width: 32px;
                    /*27*/
                    height: 32px;
                    border-radius: 4px;
                    // border: 1px solid #ddd;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .queue-title {
                    font-size: 12px;
                }
            }

            .queue-content-container:hover {
                background-color: #eee;
            }
        }
    }
}

.playbar-container--simple {
    display: none;
}


@media only screen and (max-width: 1023px) {

    .playbar-container {
        display: none;
    }

    .playbar-container--simple {
        position: fixed;
        width: calc(100% - 20px);
        display: flex;
        bottom: 70px;
        left: 0;
        height: 64px;
        background-color: #F8F8F9;
        border-radius: 16px;
        margin: 6px 10px;
        box-sizing: border-box;
        align-items: center;
        flex-direction: row;
        padding: 0 20px;
        z-index: 6; // more than play ikon on albums

        .play-controls {
            width: 27px;
            height: 27px;
            margin-right: 25px;
            margin-top: -1px;
        }

        .playback-info {
            height: 100%;
            // width: calc(100% - 150px);
            width: 100%;
            padding: 13px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            font-size: 14px;

            .playing-text {
                display: flex;
                flex-direction: column;
                column-gap: 6px;
                justify-content: flex-start;
                text-align: left;
                padding-top: 3px;

                .playing-title {
                    display: inline-block;
                    max-height: 15px;
                    /* 2 line fix */
                    overflow: hidden;
                    padding: 0;
                    line-height: 1;
                }

                .playing-title a,
                .playing-game a {
                    padding: 0;
                    line-height: 1;
                    display: inline-block;
                    text-decoration: none;
                    color: unset;
                    max-height: 15px;
                    /* 2 line fix */
                    overflow: hidden;
                }

            }

        }

        .song-option-buttons {
            width: 100px;
            /* not sure if needed */
            height: 100%;
            float: none;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
            padding-bottom: 1px;

            .song-option-btn {
                width: 36px;
                height: 36px;
                padding-top: 5px;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .song-option-btn:hover {
                background-color: transparent;
            }

            .play-control.next {
                width: 28px;
                height: 28px;
                box-sizing: content-box;
                margin-top: 1px;

                svg {
                    width: 24px;
                    height: 24px;
                }

            }

        }

    }

}